
      ;(async () => {
        const productSkusParam = new URLSearchParams(location.search).get("customer_product_skus")
        const widget = document.querySelector("tint-vto")
        let decodedProductSkus = decodeURIComponent(productSkusParam || "")
        try {
          decodedProductSkus = JSON.parse(decodedProductSkus)
        } catch (e) {
          decodedProductSkus = decodedProductSkus.split(",").filter(Boolean)
        }
        await widget.open()
        if (decodedProductSkus && decodedProductSkus.length) {
          widget.applyProductByCustomerSku(decodedProductSkus)
        } else {
          widget.applyProductByCustomerSku(["4052136075861"])
        }
        const updateUrlWithSkus = (products) => {
          const productSkus = products.map((product) => product.skus[0].sku).filter(Boolean)
          const urlParams = new URLSearchParams(location.search)
          if (productSkus.length) {
            urlParams.set("customer_product_skus", productSkus[0])
          }
          const baseUrl = window.location.href.split("?")[0]
          const newUrl = `${baseUrl}?${urlParams.toString()}`
          window.history.pushState({ path: newUrl }, "", newUrl)
        }
        widget.addEventListener("applyProduct", ({ detail }) => {
          console.debug("[ API ] applyProduct event fired", JSON.stringify(detail, null, 2))
          updateUrlWithSkus(detail)
        })
        widget.addEventListener("removeAppliedProduct", ({ detail }) => {
          console.debug("[ API ] removeAppliedProduct event fired", JSON.stringify(detail, null, 2))
          updateUrlWithSkus(detail)
        })
        widget.addEventListener("replaceAppliedProduct", ({ detail }) => {
          console.debug(
            "[ API ] replaceAppliedProduct event fired",
            JSON.stringify(detail, null, 2)
          )
        })
        widget.addEventListener("redirectToCart", () =>
          console.debug("[ API ] redirectToCart event fired")
        )
        widget.addEventListener("addToCart", ({ detail }) =>
          console.debug("[ API ] addToCart event fired", JSON.stringify(detail, null, 2))
        )
        widget.addEventListener("removeFromCart", ({ detail }) =>
          console.debug("[ API ] removeFromCart event fired", JSON.stringify(detail, null, 2))
        )
        widget.addEventListener("analysisFinished", ({ detail }) =>
          console.debug("[ API ] analysisFinished event fired", JSON.stringify(detail, null, 2))
        )
      })()
    