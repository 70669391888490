
      ;(async () => {
        const DEFAULT_MERCHANT_DEMO = "c153075e-a68c-49c5-bb7e-cb15960eba79"
        const urlParams = new URLSearchParams(location.search)
        const productSkusParam = urlParams.get("customer_product_skus")
        const merchantId = urlParams.get("merchantId") || DEFAULT_MERCHANT_DEMO
        const externalStream = urlParams.get("externalStream")
        const widget = document.querySelector("tint-vto")

        const exists = await widget.isCustomerSkuExist(["DEMO_10000001"])
        console.debug("[ API ] widget.isCustomerSkuExist([`DEMO_10000001`])", exists)

        let decodedProductSkus = decodeURIComponent(productSkusParam || "")
        try {
          decodedProductSkus = JSON.parse(decodedProductSkus)
        } catch (e) {
          decodedProductSkus = decodedProductSkus.split(",").filter(Boolean)
        }
        if (externalStream && externalStream.length && externalStream !== "null") {
          try {
            if (typeof widget["_useVideo"] === "function") {
              widget._useVideo(externalStream)
              console.log("External video blob has been passed to widget._useVideo")
            } else {
              console.error("widget._useVideo is not available")
            }
          } catch (error) {
            console.error("Failed to load external video:", error)
          }
        }
        const autostartCamera = urlParams.get("autostartCamera")
        if (autostartCamera !== "false") {
          await widget.open()
        }
        const DEFAULT_LIPSTICK = "DEMO_10000001"
        if (decodedProductSkus && decodedProductSkus.length) {
          widget.applyProductByCustomerSku(decodedProductSkus)
        } else {
          widget.applyProductByCustomerSku([DEFAULT_LIPSTICK])
        }
        const updateUrlWithSkus = (products) => {
          const productSkus = products.map((product) => product.skus[0].sku).filter(Boolean)
          const urlParams = new URLSearchParams(location.search)
          if (productSkus.length) {
            urlParams.set("customer_product_skus", productSkus[0])
          }
          const baseUrl = window.location.href.split("?")[0]
          const newUrl = `${baseUrl}?${urlParams.toString()}`
          window.history.pushState({ path: newUrl }, "", newUrl)
        }
        widget.addEventListener("applyProduct", ({ detail }) => {
          console.debug("[ API ] applyProduct event fired", JSON.stringify(detail, null, 2))
          updateUrlWithSkus(detail)
        })
        widget.addEventListener("removeAppliedProduct", ({ detail }) => {
          console.debug("[ API ] removeAppliedProduct event fired", JSON.stringify(detail, null, 2))
          updateUrlWithSkus(detail)
        })
        widget.addEventListener("replaceAppliedProduct", ({ detail }) => {
          console.debug(
            "[ API ] replaceAppliedProduct event fired",
            JSON.stringify(detail, null, 2)
          )
        })
        widget.addEventListener("redirectToCart", () =>
          console.debug("[ API ] redirectToCart event fired")
        )
        widget.addEventListener("addToCart", ({ detail }) => {
          console.debug("[ API ] addToCart event fired", JSON.stringify(detail, null, 2))
        })
        widget.addEventListener("removeFromCart", ({ detail }) => {
          console.debug("[ API ] removeFromCart event fired", JSON.stringify(detail, null, 2))
        })
        widget.addEventListener("analysisFinished", ({ detail }) => {
          console.debug("[ API ] analysisFinished event fired", JSON.stringify(detail, null, 2))
        })
      })()
    