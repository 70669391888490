
      const swatches = [
        {
          color: "#de0d01",
          images: ["./img/lipstick/lipstick-15.webp", "./img/swatch/swatch-15.webp"],
        },
        {
          color: "#824306",
          images: ["./img/lipstick/lipstick-14.webp", "./img/swatch/swatch-14.webp"],
        },
        {
          color: "#770002",
          images: ["./img/lipstick/lipstick-13.webp", "./img/swatch/swatch-13.webp"],
        },
        {
          color: "#b12e55",
          images: ["./img/lipstick/lipstick-12.webp", "./img/swatch/swatch-12.webp"],
        },
        {
          color: "#c73603",
          images: ["./img/lipstick/lipstick-11.webp", "./img/swatch/swatch-11.webp"],
        },
        {
          color: "#31001a",
          images: ["./img/lipstick/lipstick-10.webp", "./img/swatch/swatch-10.webp"],
        },
        {
          color: "#8a2e11",
          images: ["./img/lipstick/lipstick-9.webp", "./img/swatch/swatch-9.webp"],
        },
        {
          color: "#9a0025",
          images: ["./img/lipstick/lipstick-8.webp", "./img/swatch/swatch-8.webp"],
        },
        {
          color: "#bc1e7d",
          images: ["./img/lipstick/lipstick-7.webp", "./img/swatch/swatch-7.webp"],
        },
        {
          color: "#6f1b20",
          images: ["./img/lipstick/lipstick-6.webp", "./img/swatch/swatch-6.webp"],
        },
        {
          color: "#74002b",
          images: ["./img/lipstick/lipstick-5.webp", "./img/swatch/swatch-5.webp"],
        },
        {
          color: "#750023",
          images: ["./img/lipstick/lipstick-4.webp", "./img/swatch/swatch-4.webp"],
        },
        {
          color: "#940000",
          images: ["./img/lipstick/lipstick-3.webp", "./img/swatch/swatch-3.webp"],
        },
        {
          color: "#cd379b",
          images: ["./img/lipstick/lipstick-2.webp", "./img/swatch/swatch-2.webp"],
        },
        {
          color: "#b2075d",
          images: ["./img/lipstick/lipstick-1.webp", "./img/swatch/swatch-1.webp"],
        },
      ].map((swatch, id) => ({
        ...swatch,
        id,
        images: [...swatch.images, "./img/hand-swatch.webp", "./img/img-4.webp"],
      }))

      const app = Vue.createApp({
        data() {
          return {
            swatches,
            selectedSwatch: swatches[0],
            gallery: swatches[0].images,
            selectedSwatchIdx: 0,
            selectedGalleryIdx: 0,
          }
        },
        watch: {
          selectedSwatch(newSwatch) {
            if (newSwatch) {
              this.gallery = swatches[newSwatch.id].images
            }
          },
        },
        methods: {
          select(swatch, idx) {
            this.selectedSwatch = swatch
            this.selectedSwatchIdx = idx
          },
          changeGalleryImage(idx) {
            this.selectedGalleryIdx = idx
          },
          openWidget() {
            const widget = document.querySelector("tint-vto")
            widget.open()
          },
        },
      }).mount("#app")
    