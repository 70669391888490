
      const DEFAULT_MERCHANT_DEMO = "c153075e-a68c-49c5-bb7e-cb15960eba79"
      const searchParams = new URLSearchParams(location.search)
      const shopifyStoreId = searchParams.get("shopifyStoreId")
      const shopifyHost = searchParams.get("shopifyHost")
      const token = searchParams.get("token")
      const merchantId = searchParams.get("merchantId") || DEFAULT_MERCHANT_DEMO
      const lang = searchParams.get("lang")
      let template = `<tint-vto></tint-vto>`
      if (shopifyStoreId) {
        template = `<tint-vto lang="${lang}" shopify-store-id="${shopifyStoreId}" shopify-host="${shopifyHost}"></tint-vto>`
      }
      if (token) {
        template = `<tint-vto token="${token}"></tint-vto>`
      }
      if (merchantId) {
        template = `<tint-vto merchant-id="${merchantId}"></tint-vto>`
      }
      if (lang) {
        template = `<tint-vto lang="${lang}"></tint-vto>`
      }
      document.body.insertAdjacentHTML("beforeend", template)
      window.TINT_GVTO = document.querySelector("tint-vto")
    