
      import { isBrowserSupported } from "./src/index.ts"

      if (!isBrowserSupported()) {
        const template = document.createElement("template")
        template.innerHTML = `
          <div class="tint-banner flex fixed z-10 items-center bg-[#f9b74a] px-4 py-2 leading-5">
            <img src="/shape.svg" alt="Banner Icon" class="mr-2"/>
            <p>
              For this browser, only a beta version is available. For a better
              experience, please use <b>Google Chrome.</b>
            </p>
          </div>
        `
        document.body.prepend(template.content)
      }
    